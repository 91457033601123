import React, { useCallback, useEffect, useState } from 'react';
import { Button, useTheme, Typography } from '@mui/material';
import { Menu, AppSettingsAlt, Check, Info, InfoOutlined, ContactMail, EmailOutlined, SettingsApplications, PhoneIphone, ArticleOutlined, PersonOutlineOutlined, SwipeUp, Quiz, MenuBook } from '@mui/icons-material';
import { useMediaPredicate } from 'react-media-hook';
import './Navbar.css';
import { useTranslation } from "react-i18next";

import LoveguardLogoComponent from '../Shared/LoveguardLogoComponent';
import { useNavigate } from 'react-router-dom';
import { delay } from 'framer-motion';
import { IsAndroid } from '../Shared/IsAndroid';
import { IsIos } from '../Shared/IsIos';


interface NavbarProps {
    // onSignUp: () => void;
    // onGoApp: () => void;
    // onGoContact: () => void;

}

export default function Navbar(props: NavbarProps) {

    const biggerThan700 = useMediaPredicate("(min-width: 700px)");
    const navigate = useNavigate();
    const theme = useTheme();
    const { t } = useTranslation();
    const [isOpenedDropDown, setIsOpenedDropDown] = useState(false);
    const [y, setY] = useState(window.scrollY);
    const [shouldClose, setShouldClose] = useState(false);

    const handleNavigation = useCallback(
        (e: any) => {
            const window = e.currentTarget;
            if ((y > window.scrollY || y < window.scrollY) && shouldClose === false) {
                console.log("scrolling up");
                setShouldClose(true);
            }
            setY(window.scrollY);
        }, [y]
    );

    const redirectToShop = () => {
        if (IsAndroid()) {
            // Redirect to Google Play Store if the user is on Android
            window.location.href = 'https://play.google.com/store/apps/details?id=com.Loveguard.App'; // Replace with your actual Google Play Store URL
        } else if (IsIos()) {
            // Redirect to Apple App Store if the user is on iOS
            window.location.href = 'https://apps.apple.com/cz/app/loveguard/id6689517134'; // Replace with your actual Apple App Store URL
        } else {
            // Redirect to Google Play Store if the user is on Android
            window.location.href = 'https://apps.apple.com/cz/app/loveguard/id6689517134?platform=iphone'; // Replace with your actual Google Play Store URL;
        }
    }

    useEffect(() => {
        if (shouldClose === true) {
            delay(() => {
                setIsOpenedDropDown(false);
                setShouldClose(false);
            }, 100);
        }
    }, [shouldClose])
    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);

        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);
    return (
        <div>
            <div style={{ display: isOpenedDropDown ? 'flex' : 'none', flexDirection: 'column', width: '100%', height: '228px', position: 'fixed', top: '60px', zIndex: '20000', background: '#1F1F1F' }} >
                <Button href="#app">Aplikace</Button>
                <Button href="#vyzvy">Výzvy</Button>
                <Button href="#kviz">Kvíz</Button>
                <Button onClick={() => { navigate('/ebook') }}>Ebook</Button>
                <Button href="#contact">Kontakt</Button>
                <Button onClick={redirectToShop} variant='contained'>Registrace</Button>
            </div>
            <nav className="Navbar">

                <LoveguardLogoComponent />

                <div>
                    {biggerThan700 && <div style={{ display: 'flex' }} >
                        <div className='link-wrapper'>
                            <PhoneIphone color='primary' style={{ height: '20px', width: '20px', marginTop: '4px', marginRight: '4px' }} />
                            <a style={{ color: 'white', textDecoration: 'none', marginTop: '2px' }} href="/#app"><Typography>{t('App')}</Typography></a>
                        </div>
                        {/* <div className='link-wrapper'>
                        <ArticleOutlined color='primary' style={{ height: '20px', width: '20px', marginTop: '4px', marginRight: '4px' }} />
                        <a style={{ color: theme.palette.text.primary, textDecoration: 'none', marginTop: '2px' }} href="#articles"><Typography>{t('Articles')}</Typography></a>
                    </div>
                    <div className='link-wrapper'>
                        <InfoOutlined color='primary' style={{ height: '20px', width: '20px', marginTop: '4px', marginRight: '4px' }} />
                        <a style={{ color: theme.palette.text.primary, textDecoration: 'none', marginTop: '2px' }} href="#about"><Typography>{t('AboutUs')}</Typography></a>
                    </div> */}
                        <div className='link-wrapper'>
                            <SwipeUp color='primary' style={{ height: '20px', width: '20px', marginTop: '4px', marginRight: '4px' }} />
                            <a style={{ color: 'white', textDecoration: 'none', marginTop: '2px' }} href="/#vyzvy"><Typography>{t('Challenges')}</Typography></a>
                        </div>
                        <div className='link-wrapper'>
                            <Quiz color='primary' style={{ height: '20px', width: '20px', marginTop: '4px', marginRight: '4px' }} />
                            <a style={{ color: 'white', textDecoration: 'none', marginTop: '2px' }} href="/quiz"><Typography>{t('Quiz')}</Typography></a>
                        </div>
                        <div className='link-wrapper'>
                            <MenuBook color='primary' style={{ height: '20px', width: '20px', marginTop: '4px', marginRight: '4px' }} />
                            <Button onClick={() => { navigate('/ebook') }} variant='text' style={{marginTop: '-4px', color: 'white', marginLeft: '-8px'}}>{t('Ebook')}</Button>
                        </div>
                        <div className='link-wrapper'>
                            <EmailOutlined color='primary' style={{ height: '20px', width: '20px', marginTop: '4px', marginRight: '4px' }} />
                            <a style={{ color: 'white', textDecoration: 'none', marginTop: '2px' }} href="/#contact"><Typography>{t('Contact')}</Typography></a>
                        </div>
                    </div>}

                </div>
                {biggerThan700 && <div style={{ display: 'flex', color: theme.palette.primary.main }} >

                    <Button 
                    onClick={redirectToShop}
                    variant='text'>{t('SignUp')}<PersonOutlineOutlined style={{ height: '24px', width: '24px', marginLeft: '8px' }} /></Button></div>}
                {!biggerThan700 && 
                <Button onClick={() => { setIsOpenedDropDown(!isOpenedDropDown) }}>
                    <Menu />
                </Button>}
            </nav>
        </div>
    );
}